import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageCodes, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Proyecto } from 'src/app/api/articulo';
import { ProyectoPsService } from 'src/app/procs/proyecto-ps.service';

@Component({
  selector: 'axks-proyecto-cat',
  templateUrl: './proyecto-cat.component.html',
  styleUrls: ['./proyecto-cat.component.scss']
})
export class ProyectoCatComponent implements OnInit, OnDestroy, OnChanges {

  catalog: Proyecto[];
  virtualScroll: boolean = false;

  showForm: boolean = false;
  newItem: Proyecto;

  @Input() esBodega: boolean;

  @Input() item?: Proyecto;
  /**
   * Habilita la funcionalidad para agregar
   */
  @Input() addNewEnable: boolean = false;

  /** 
   * Indica si hay que tomar en cuenta el modo de operación 
   * de la pantalla.
   */
  @Input() operationMode: OperationMode;

  @Output() onChange = new EventEmitter<number>();


  constructor(private psProyecto: ProyectoPsService,
    private messages: MessageService,
    private route: ActivatedRoute) {

  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {} as Proyecto;
    }
    if (this.operationMode) {
      this.enableAddNewButton();
    }

    // Temporal mientras se desarrolla
    /* this.route.data.subscribe((data) => {
      this.addNewEnable = data.addNewEnable;
      this.populate();
    }) */

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
    this.enableAddNewButton();

    if(this.esBodega) {
      this.item.id = null;
      this.onChange.emit(this.item.id);
    }
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
        this.addNewEnable = true;
        break;
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {} as Proyecto;

    this.virtualScroll = false;
    this.psProyecto.find(filter).subscribe(
      {
        next: (data) => {
          this.catalog = data as Proyecto[];
          if (this.catalog.length > 10) {
            this.virtualScroll = true;
          }
        },
        error: (error) => {
          try {

            let message = error.error as Message;
            this.processMessage(message);
          } catch (e) {
            console.debug("Error")
            console.debug(error)
          }
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron los Proyectos. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo de Proyectos.", detail: messText });
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = {} as Proyecto;
  }

  onSaveClick(event) {
    this.psProyecto.register(this.newItem).subscribe(
      {
        next: (data) => {
          this.populate();
          this.showForm = false;
          this.newItem = undefined;
        },
        error: (error) => {
          try {
            let message = error.error as Message;
            this.processMessage(message);
          } catch (e) {
            console.debug("Error")
            console.debug(error)
          }
          this.showForm = false;
          this.newItem = undefined;
        }
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = undefined;
  }

  selectOption(event) {
    this.onChange.emit(this.item.id);
  }

}