import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticuloComponent } from './views/articulo/articulo/articulo.component';
import { CategoriaComponent } from './views/categoria/categoria/categoria.component';
import { MarcaComponent } from './views/marca/marca/marca.component';
import { PropiedadComponent } from './views/propiedad/propiedad/propiedad.component';
import { TipoArticuloComponent } from './views/tipo-articulo/tipo-articulo/tipo-articulo.component';
import { UnidadMedidaComponent } from './views/unidad-medida/unidad-medida/unidad-medida.component';
import { TipomovimientoComponent } from './views/tipomovimiento/tipomovimiento/tipomovimiento.component';
import { MovimientoAlmacenComponent } from './views/movimiento-almacen/movimiento-almacen/movimiento-almacen.component';
import { HomeComponent } from './views/home/home.component';
import { TipoMaterialComponent } from './views/tipo-material/tipo-material/tipo-material.component';
import { MaterialComponent } from './views/material/material/material.component';
/* import { APP_BASE_HREF } from '@angular/common';
import { environment } from 'src/environments/environment'; */
const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "herramienta", component: ArticuloComponent },
  { path: "materiales", component: MaterialComponent },
  { path: "categoria", component: CategoriaComponent },
  { path: "marca", component: MarcaComponent },
  { path: "propiedad", component: PropiedadComponent },
  { path: "tipo-articulo", component: TipoArticuloComponent },
  { path: "unidad-medida", component: UnidadMedidaComponent },
  { path: "tipo-movimiento", component: TipomovimientoComponent },
  { path: "movimiento-almacen", component: MovimientoAlmacenComponent },
  { path: "tipo-material", component: TipoMaterialComponent },
  //{path:"dev/catalog", component:MarcaCatComponent, data:{addNewEnable:true}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
  /* providers: [{provide: APP_BASE_HREF, useValue: "/"+environment.basePath+"/"}] */
})
export class AppRoutingModule { }
