import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { PropiedadRestcService } from '../client/propiedad-restc.service';

@Injectable({
  providedIn: 'root'
})
export class PropiedadPsService extends BaseProcess<PropiedadRestcService> {

  constructor(httpClient: HttpClient, crest: PropiedadRestcService) {
    super(httpClient, crest);
   }
}