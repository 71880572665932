import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
  })
  export class EstadoarticuloRestcService extends ARestClient{
  
    constructor(protected client:HttpClient) { 
      super(client);
    }
  
    getBaseEndpoint(): string {
      return environment.client.msEstadoArticulo+"/";
    }
    processBody(res: any) {
      return res || {};
    }
  
    getRestPaths(): RestBasicPaths{
      let restPaths = super.getRestPaths();
      restPaths.detail='';
      restPaths.remove='';
      return restPaths;
    }
  }