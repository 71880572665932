<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <!-- {{item.item | json}} -->
    <axks-operations-bar (addNew)="addNew($event)" [addNewButton]="true" [searchButton]="true"
        [sectionTitle]="getComponentTitle()" (filter)="filterClick($event)" [filterButton]="true" [searchButton]="false">
    </axks-operations-bar>

    <p-overlayPanel #searchFilter (onHide)="hideFilter($event)">
        <ng-container *ngIf="showFilter">
            <axks-unidad-medida-form #cmpForm id="cmpForm" [item]="workItem()" [formSearch]="formSearch"
                [formFields]="formFields" [operationMode]="operationMode"></axks-unidad-medida-form>

            <axks-actions-bar (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
                cleanLabel="Limpiar">
            </axks-actions-bar>
        </ng-container>

    </p-overlayPanel>

    <axks-detail-tab *ngIf="showDetail()" [data]="workItem()" [colsDefinition]="colDefinition"
        [detailFields]="detailFields" withLateral="laterlaMode()" [style]="isDesktop()?'--width: 87%':''">
    </axks-detail-tab>

    <axks-unidad-medida-form #cmpForm id="cmpForm" *ngIf="showForm() && showFilter==false" [item]="workItem()" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-' + operationMode"
        [ngStyle]="{float: isSearch()?'left':'unset'}" [class]="'form-container ' + (lateralMode() ? 'with-lateral' : '')">
    </axks-unidad-medida-form>

    <axks-actions-bar *ngIf="showMainActionsBar()" (save)="save($event)" (cancel)="cancel($event)"
        (clean)="clean($event)" (find)="find($event)" (print)="print($event)" [printButton]="false"
        (edit)="edit($event)" (delete)="delete($event)" (csvFile)="csvFile($event)" (pdfFile)="pdfFile($event)"
        (txtFile)="txtFile($event)" saveIcon="true" cancelIcon="true" cleanIcon="true" findIcon="true" editIcon="true"
        deleteIcon="true"
        [extraButtons]="extraButtons" [lateral]="lateralMode()" [class]="lateralMode() ? 'lateral-bar' : ''">
    </axks-actions-bar>


    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()" [class]="!isExpandedResultList() ? 'with-lateral' : ''">
        <ng-container position="caption"></ng-container>
    </axks-result-list>

<p-toast></p-toast>