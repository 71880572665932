import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { EmpleadosRestcService } from '../client/empleados-restc.service';
@Injectable({
  providedIn: 'root'
})
export class EmpleadosPsService extends BaseProcess<EmpleadosRestcService>{

  client: EmpleadosRestcService;

  constructor(httpClient: HttpClient, crest: EmpleadosRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  empleadosActivos(idEmpresa: number): Observable<any[]> {
    return this.client.empleadosActivos(idEmpresa);
  }
}
