import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, Message, MessageCodes } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DetalleHistorico } from 'src/app/api/articulo';
import { EmpleadosPsService } from 'src/app/procs/empleados-ps.service';
import { MovimientoalmacenPsService } from 'src/app/procs/movimientoalmacen-ps.service';

@Component({
  selector: 'axks-historico-form',
  templateUrl: './historico-form.component.html',
  styleUrls: ['./historico-form.component.scss']
})
export class HistoricoFormComponent extends BaseView implements OnInit{
  @Input() historico: DetalleHistorico[];
  @Input() visible: boolean = false;
  virtualScroll: boolean = false;
  historicoDet: DetalleHistorico[]=[];
  @Input() idArticulo: number;
  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messages: MessageService,
    private clientEmp: EmpleadosPsService, 
    private psHistorico: MovimientoalmacenPsService,
    protected sessionProvier: SessionClientProvider) { 
  
    super(screenModeService, deviceService, messages, sessionProvier);
  }
  ngOnInit(): void {
    
    if(!this.historico) {
      this.historico = [];
    } 

    this.psHistorico.historico(this.idArticulo).subscribe(
      (data) => {
        this.visible=false;
        this.historico = data
        console.log(this.historico);
      }
    )

  }
  
  /* ngOnChanges(changes: SimpleChanges): void {
    this.populate();
    
  } */
  
  }
  

