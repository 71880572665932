import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, Message, MessageCodes } from '@axks/components';
import { SimpleChange } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { OperationMode } from '@axks/components';
import { Articulo } from 'src/app/api/articulo';
import { MovimientoAlmacenReg } from 'src/app/api/articulo';
import { EmpleadosPsService } from 'src/app/procs/empleados-ps.service';
import { MovimientoalmacenPsService } from 'src/app/procs/movimientoalmacen-ps.service';
import { MovimientoalmacenRestcService } from 'src/app/client/movimientoalmacen-restc.service';
import { ProyectosPsService } from 'src/app/procs/proyectos-ps.service';
@Component({
  selector: 'axks-material-salida-form',
  templateUrl: './material-salida-form.component.html',
  styleUrls: ['./material-salida-form.component.scss']
})
export class MaterialSalidaFormComponent extends BaseView implements OnInit{
  @Input() item: MovimientoAlmacenReg;
  @Output() onChange = new EventEmitter<number>();
  @Output() onHide = new EventEmitter<number>();
  @Input() visible: boolean = false;
  @Input() idArticulo: number;
  proyectos: any[];
  virtualScroll: boolean = false;
  empleadosNombre: any[];
  catalog: MovimientoAlmacenReg[];
  
  @Output() onHideSalida = new EventEmitter<boolean>();
  
  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messages: MessageService,
    private clientEmp: EmpleadosPsService, 
    private psSalida: MovimientoalmacenPsService,
    private clientProyecto: ProyectosPsService,
    protected sessionProvier: SessionClientProvider) { 
  
    super(screenModeService, deviceService, messages, sessionProvier);
  }
  ngOnInit(): void {
    if(!this.item) 
      this.item = {
        idArticulo: this.idArticulo
       };
  
      this.initEmpleadosNombre();
      /* this.initProyectos(); */
  }
  
  /* ngOnChanges(changes: SimpleChanges): void {
    this.populate();
    
  } */
  
  initEmpleadosNombre() {  //lo acabo de agregar
    this.clientEmp.empleadosActivos(1).subscribe(
      (data) => {
        this.empleadosNombre = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo cargar el catalogo de empleados");
      }
    );
  }
  
  selProyecto(event) {
    this.item.idProyecto = event;
  }
  
  dialogSize(): any{
    if(this.isMobile()){
      return {width: '90vw', height:'90%'};
    }
  
    return {width: '60vw', height:'60%'};
  }
  gridSize(): string{
    if(this.isMobile()){
      return 'height:76vh';
    }
  
    return 'height:46vh';
  }
  onHideDialog(event){
    this.onHide.emit();
  }
  
  selectOption(event) {
    this.onChange.emit(event.value);
  }
  
  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron los Aritculos. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }
  
    this.messages.add({ severity: sevMes, summary: "Catálogo de Articulos.", detail: messText });
  }
  
  /* populate() {
    let filter = {} as MovimientoAlmacenReg;
  
    this.virtualScroll = false;
    this.psSalida.find(filter).subscribe(
      {
        next: (data) => {
          this.catalog = data as MovimientoAlmacenReg[];
          if (this.catalog.length > 10) {
            this.virtualScroll = true;
          }
        },
        error: (error) => {
          try {
  
            let message = error.error as Message;
            this.processMessage(message);
          } catch (e) {
            console.debug("Error")
            console.debug(error)
          }
        }
      }
    );
  } */
  
  onCancelar($event){
    this.visible=false;
  }
  
  onGuardar(event){
    if(this.item.cantidad && this.item.comentario) {
      this.item.idArticulo = this.idArticulo;
      this.psSalida.registrarSalida(this.item).subscribe(
        (data) => {
          this.onHideSalida.emit(true);
          this.successMessage("Listo", "Se registro la salida correctamente");
        },
        (error)=>{
          this.errorMessage("Error", error.error.message);
        }
      )
  
    }
    else {
      this.infoMessage("Atencion", "Campos faltantes");
    }


/*     initProyectos() {
      this.clientProyecto.find({}).subscribe(
        (data) => {
          this.proyectos = data;
        },
        (error) => {
          this.errorMessage("Error", "No se pudo cargar el catalogo de proyectos");
        }
      );
    } */
    /* this.clientMov.register(this.item).subscribe(
      {
        next: (data) => {
          this.populate();
          this.showForm = false;
          this.newItem = undefined;
        },
        error: (error) => {
          try {
            let message = error.error as Message;
            this.processMessage(message);
          } catch (e) {
            console.debug("Error")
            console.debug(error)
          }
          this.showForm = false;
          this.newItem = undefined;
        }
      }); */
  }
}
