import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { OperationMode } from '@axks/components';
import { Articulo } from 'src/app/api/articulo';
import { MovimientoAlmacenReg } from 'src/app/api/articulo';
import { EmpleadosPsService } from 'src/app/procs/empleados-ps.service';
import { MovimientoalmacenRestcService } from 'src/app/client/movimientoalmacen-restc.service';
import { MovimientoalmacenPsService } from 'src/app/procs/movimientoalmacen-ps.service';
@Component({
  selector: 'axks-material-entrada-form',
  templateUrl: './material-entrada-form.component.html',
  styleUrls: ['./material-entrada-form.component.scss']
})
export class MaterialEntradaFormComponent extends BaseView implements OnInit{

  @Input() item: MovimientoAlmacenReg;
@Output() onChange = new EventEmitter<number>();
@Output() onHide = new EventEmitter<number>();
@Input() visible: boolean = false;
@Input() idArticulo: number;
display: boolean = false;
@Output() onHideEntrada = new EventEmitter<boolean>();
/* virtualScroll: boolean = false;
empleadosNombre: any[]; */
constructor(protected screenModeService: OperationScreenModeService, 
  protected deviceService: DeviceDetectorService, 
  protected messageService: MessageService,
  private clientEmp: EmpleadosPsService, 
  private psEntrada: MovimientoalmacenPsService,
  protected sessionProvier: SessionClientProvider) { 

  super(screenModeService, deviceService, messageService, sessionProvier);
}
ngOnInit(): void {
  if(!this.item) 
    this.item = { 
      idArticulo: this.idArticulo
    };
}

dialogSize(): any{
  if(this.isMobile()){
    return {width: '90vw', height:'90%'};
  }

  return {width: '60vw', height:'60%'};
}
gridSize(): string{
  if(this.isMobile()){
    return 'height:76vh';
  }

  return 'height:46vh';
}
onHideDialog(event){
  this.onHide.emit();
}

onGuardar(event){
  if( this.item.comentario) {
    this.item.idArticulo = this.idArticulo;
    this.successMessage("Listo", "Guardado");
    this.psEntrada.registrarEntrada(this.item).subscribe(
      (data) => {
        this.onHideEntrada.emit(true);
      },
      (error)=>{
        console.debug("No se asigno el material: ");
        console.debug(error);
      }
    )

  }
  else {
    this.infoMessage("Atencion", "Campos faltantes");
  }
}
onCancelar(event){
  this.display = false;
  /* console.log('Sirve o no boton cancelar') */
}
}
