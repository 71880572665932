import { Component, OnInit } from '@angular/core';
import { formatCurrency, formatDate } from '@angular/common';
import {
  BaseMainComponent, ColumnDefinition, ComponentItem,
  OperationScreenModeService, SessionClientProvider
} from '@axks/components';
import { MovimientoalmacenPsService } from 'src/app/procs/movimientoalmacen-ps.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { MovimientoAlmacen, MovimientoAlmacenReg } from 'src/app/api/articulo';
@Component({
  selector: 'axks-movimiento-almacen',
  templateUrl: './movimiento-almacen.component.html',
  styleUrls: ['./movimiento-almacen.component.scss']
})
export class MovimientoAlmacenComponent extends BaseMainComponent<MovimientoalmacenPsService>implements OnInit{
  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: MovimientoalmacenPsService) {
    super(deviceService, messageService, screenModeService,
      sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        /* tipoMovimiento: {
        } */
      } as MovimientoAlmacen,

      minimumItem: {
  
      } as MovimientoAlmacen,

      registerItem: {
        /* tipoMovimiento: {
        } */
      } as MovimientoAlmacenReg

    } as ComponentItem;
    return empty;
  }

  getService(): MovimientoalmacenPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'idMovAlmacen', inDetail: true, inResultList: true },
      { header: 'Articulo', field: 'articulo', inDetail: true, inResultList: true },
      { header: 'Tipo de movimiento', field: 'tipoMovimiento', inDetail: true, inResultList: true },
      { header: 'Partes unidad', field: 'partesUnidad', inDetail: true, inResultList: true},
      { header: 'Cantidad unidad', field: 'cantidadUnidad', inDetail: true, inResultList: true},
      { header: 'Fecha Movimiento', field: 'fecMovimiento', inDetail: true, inResultList: true, formatFunction: this.formatDate },
      { header: 'Id empleado', field: 'idEmpleado', inDetail: true, inResultList: true }, 
      { header: 'Proyecto', field: 'Proyecto', inDetail: true, inResultList: true },
      { header: 'Comentario', field: 'comentario', inDetail: true, inResultList: true },
    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'MovimientoAlmacen';
  }

  /* rowSelect(event) {
    this.item.item = this.initEmptyItem().item;
    this.extraButtons = [];
    super.rowSelect(event);
    setTimeout(() => {
      let proyecto = this.item.item as Proyecto;
 
      if (!proyecto.asignado) {
        this.initButtonsAsigna();
      } else {
        this.initButtonsRecibe();
      } */
  //this.extraButtons = [];

  /*  }, 1000);
 } */


formatDate(date: string): string {
    let fechaDate = new Date(date);
    let mes: string;
    switch (fechaDate.getMonth()) {
      case 0: {
        mes = 'Enero';
        break;
      }
      case 1: {
        mes = 'Febrero';
        break;
      }
      case 2: {
        mes = 'Marzo';
        break;
      }
      case 3: {
        mes = 'Abril';
        break;
      }
      case 4: {
        mes = 'Mayo';
        break;
      }
      case 5: {
        mes = 'Junio';
        break;
      }
      case 6: {
        mes = 'Julio';
        break;
      }
      case 7: {
        mes = 'Agosto';
        break;
      }
      case 8: {
        mes = 'Septiembre';
        break;
      }
      case 9: {
        mes = 'Octubre';
        break;
      }
      case 10: {
        mes = 'Noviembre';
        break;
      }
      case 11: {
        mes = 'Diciembre';
        break;
      }
      default: {
        break;
      }
    }

    return fechaDate.getDate() + " de " + mes + " de " + fechaDate.getFullYear();
  } 

/*   formatFecha(value: string): string {
    return value ? formatDate(value, "dd 'de' MMMM 'de' yyyy, hh:mm a", 'es-MX') : null;
  } */
  
  componentsFields() {
    super.componentsFields();
    this.formFields = ["id", "idArticulo", "idTipoMovimiento", "partesUnidad", "cantidadUnidad", "fecMovimiento", "idEmpleado", "idProyecto", "comentario",  "nombreEmpleado",];
    this.formSearch = ["id", "idArticulo", "idTipoMovimiento", "partesUnidad", "cantidadUnidad", "fecMovimiento", "idEmpleado", "idProyecto", "comentario", /* "articulo", */ "nombreEmpleado",];
  }


}

