import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipomovimientoRestcService } from '../client/tipomovimiento/tipomovimiento-restc.service';
@Injectable({
  providedIn: 'root'
})
export class TipomovimientoPsService extends BaseProcess<TipomovimientoRestcService>{

  constructor(httpClient: HttpClient, crest: TipomovimientoRestcService) {
    super(httpClient, crest);
   }
}
