import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoArticuloRestcService } from '../client/tipo-articulo-restc.service';

@Injectable({
  providedIn: 'root'
})
export class TipoArticuloPsService extends BaseProcess<TipoArticuloRestcService>{

  constructor(httpClient: HttpClient, crest: TipoArticuloRestcService) {
    super(httpClient, crest);
   }
}
