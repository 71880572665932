import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoArticulo } from 'src/app/api/articulo';

@Component({
  selector: 'axks-tipo-articulo-form',
  templateUrl: './tipo-articulo-form.component.html',
  styleUrls: ['./tipo-articulo-form.component.scss']
})
export class TipoArticuloFormComponent extends BaseView implements OnInit{

  @Input() item: TipoArticulo;

  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider) { 

    super(screenModeService, deviceService, messageService, sessionProvier);
  }


  ngOnInit(): void {
    if(!this.item)
      this.item = {};
  }

  changeUnidadMedida(event){
    
    this.item.idUnidadMedida = event;
    
  }

  changeCategoria(event){
    
    this.item.idCategoria = event;
    
  }

}
