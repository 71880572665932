<div class="grid frm-part">
    
    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('id')">
        <br>
        <span class="p-float-label">
            <input id="fl-id" type="text" pInputText [(ngModel)]="item.id">
            <label for="fl-id">Id tipo de movimiento</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('tipoMovimiento')">
        <br>
        <span class="p-float-label">
            <input id="fl-tipomovimiento" type="text" pInputText [(ngModel)]="item.tipoMovimiento">
            <label for="fl-tipomovimiento">Tipo de movimiento</label>
        </span>
    </div>
</div>
