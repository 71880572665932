import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { MovimientoAlmacenReg } from '../api/articulo';
import { DetalleHistorico } from '../api/articulo';
import { MovimientoalmacenRestcService } from '../client/movimientoalmacen-restc.service';

@Injectable({
  providedIn: 'root'
})
export class MovimientoalmacenPsService extends BaseProcess<MovimientoalmacenRestcService>{

  client: MovimientoalmacenRestcService;

  constructor(httpClient: HttpClient, crest: MovimientoalmacenRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  registrarSalida(item: MovimientoAlmacenReg) {
    return this.client.registrarSalida(item);
  }

  registrarEntrada(item: MovimientoAlmacenReg) {
    return this.client.registrarEntrada(item);
  }

  historico(idArticulo: number): Observable<DetalleHistorico[]> {
    return this.client.historico(idArticulo);
  }
}
