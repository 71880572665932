import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { OperationMode } from '@axks/components';
import { EstadoarticuloPsService } from 'src/app/procs/estado-articulo-ps.service';
import { Articulo, EstadoArticulo } from 'src/app/api/articulo';
import { ProyectoPsService } from 'src/app/procs/proyecto-ps.service';
import { ProyectosPsService } from 'src/app/procs/proyectos-ps.service';
import { ArticuloPsService } from 'src/app/procs/articulo-ps.service';
@Component({
  selector: 'axks-mantenimiento-form',
  templateUrl: './mantenimiento-form.component.html',
  styleUrls: ['./mantenimiento-form.component.scss']
})
export class MantenimientoFormComponent extends BaseView implements OnInit{
  @Input() item: Articulo;
  estados: EstadoArticulo[];
  @Output() onChange = new EventEmitter<number>();
  @Output() onHide = new EventEmitter<number>();
  virtualScroll: boolean = false;
  esBodega: boolean = false;
  proyectos: any[];
  @Input() idArticulo: number;
  @Input() idCategoria: Number=2;
  //const categoria?: Number = 1;
  @Input() addNewEnable: boolean = false;
  showForm: boolean = false;
  OPERATIONMODE = OperationMode;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private clientEstado: EstadoarticuloPsService,
    private psArticulo: ArticuloPsService,
    protected sessionProvier: SessionClientProvider) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item)
      this.item = {
        //idCategoria: this.idCategoria =2,
      };

    this.initEstados(); }


    selectOption(event) {
      this.onChange.emit(this.item.idEstadoArticulo);
    }
  
    initEstados() {
      this.clientEstado.find({}).subscribe(
        (data) => {
          this.estados = data;
        },
        (error) => {
          this.errorMessage("Error", "No se pudo cargar el catalogo de estados");
        }
      );
    }

    onGuardar(event){
      if(this.item.idEstadoArticulo) {
        this.item.idArticulo = this.idArticulo;
        this.successMessage("Listo", "Se le dio salida");
        this.psArticulo.edit(this.item).subscribe(
          (data) => {
            this.onHide.emit(event);
          },
          (error)=>{
            console.debug("No se asigno la herramienta: ");
            console.debug(error);
          }
        )
    
      }
      else {
        this.infoMessage("Atencion", "Campos faltantes");
      }
}
}