import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { UnidadMedida } from 'src/app/api/articulo';
import { UnidadMedidaPsService } from '../../../procs/unidad-medida-ps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-unidad-medida',
  templateUrl: './unidad-medida.component.html',
  styleUrls: ['./unidad-medida.component.scss']
})
export class UnidadMedidaComponent extends BaseMainComponent<UnidadMedidaPsService> implements OnInit{
  

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    protected unidadMedidaPs: UnidadMedidaPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }


  initEmptyItem(): ComponentItem {
    return {
      item: {} as UnidadMedida,
      minimumItem: {} as UnidadMedida,
      registerItem: {} as UnidadMedida
    } as ComponentItem;
  }
  getService(): UnidadMedidaPsService {
    return this.unidadMedidaPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {field: "id", header: "Clave", inAll:true},
      {field: "unidadMedida", header:"Unidad de medida", inAll:true}
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Unidad de medida";
  }
}
