<div class="grid">
    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <p-timeline [value]="historico">
            <ng-template pTemplate="content" let-event>
                <small class="p-text-secondary">{{ event.fecMovimiento | date: "dd 'de' MMMM 'de' yyyy, hh:mm a":
                    "America/Mexico_City":"es-MX"}}</small>
            </ng-template>
            <ng-template pTemplate="opposite" let-event>
                <span>{{ event.tipoMovimiento }}</span><br><span>{{event.nombre}}</span><br><span>Se encuentra en optimas condiciones: {{event.estadoRegreso}}</span>
            </ng-template>
        </p-timeline>
    </div>
</div>