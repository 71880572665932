import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, Message, MessageCodes } from '@axks/components';
import { SimpleChange } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { OperationMode } from '@axks/components';
import { Articulo } from 'src/app/api/articulo';
import { EstadoArticulo } from 'src/app/api/articulo';
import { MovimientoAlmacenReg } from 'src/app/api/articulo';
import { EmpleadosPsService } from 'src/app/procs/empleados-ps.service';
import { MovimientoalmacenPsService } from 'src/app/procs/movimientoalmacen-ps.service';
import { MovimientoalmacenRestcService } from 'src/app/client/movimientoalmacen-restc.service';
import { EstadoarticuloPsService } from 'src/app/procs/estado-articulo-ps.service';
@Component({
  selector: 'axks-articulo-salida-form',
  templateUrl: './articulo-salida-form.component.html',
  styleUrls: ['./articulo-salida-form.component.scss']
})
export class ArticuloSalidaFormComponent extends BaseView implements OnInit{
//ASOCIAR PARA QUE SALGA EN EL MOMENTO QUE SE DE CLIC AL BOTON SALIDA
//TODOS LOS CAMPOS METERLOS AQUI COMO DIALOG https://primeng.org/dialog
@Input() item: MovimientoAlmacenReg;
@Output() onChange = new EventEmitter<number>();
@Output() onHide = new EventEmitter<number>();
@Input() visible: boolean = false;
@Input() idArticulo: number;
virtualScroll: boolean = false;
@Input() addNewEnable: boolean = false;
empleadosNombre: any[];
estados: EstadoArticulo[];
catalog: MovimientoAlmacenReg[];
showForm: boolean = false;
@Output() onHideSalida = new EventEmitter<boolean>();

constructor(protected screenModeService: OperationScreenModeService, 
  protected deviceService: DeviceDetectorService, 
  protected messages: MessageService,
  private clientEmp: EmpleadosPsService, 
  private psSalida: MovimientoalmacenPsService,
  private clientEstado: EstadoarticuloPsService,
  protected sessionProvier: SessionClientProvider) { 

  super(screenModeService, deviceService, messages, sessionProvier);
}
ngOnInit(): void {
  if(!this.item) 
    this.item = {
      idArticulo: this.idArticulo
     };

    this.initEmpleadosNombre();
    this.initEstados();
}

/* ngOnChanges(changes: SimpleChanges): void {
  this.populate();
  
} */

initEmpleadosNombre() {  //lo acabo de agregar
  this.clientEmp.empleadosActivos(1).subscribe(
    (data) => {
      this.empleadosNombre = data;
    },
    (error) => {
      this.errorMessage("Error", "No se pudo cargar el catalogo de empleados");
    }
  );
}

initEstados() {
  this.clientEstado.find({}).subscribe(
    (data) => {
      this.estados = data;
    },
    (error) => {
      this.errorMessage("Error", "No se pudo cargar el catalogo de estados");
    }
  );
}

selProyecto(event) {
  this.item.idProyecto = event;
}

selectEstado(event) {
  this.onChange.emit(this.item.idEstadoArticulo);
}

dialogSize(): any{
  if(this.isMobile()){
    return {width: '90vw', height:'90%'};
  }

  return {width: '60vw', height:'60%'};
}
gridSize(): string{
  if(this.isMobile()){
    return 'height:76vh';
  }

  return 'height:46vh';
}
onHideDialog(event){
  this.onHide.emit();
}

selectOption(event) {
  this.onChange.emit(event.value);
}

processMessage(message: Message) {
  let messText: string;
  let sevMes = "info";
  switch (message.code) {
    case MessageCodes.NOT_FOUND_RESULTS.valueOf():
      messText = "No se localizaron los Aritculos. Deberá registrarlos primero.";
      break;
    default:
      messText = "Error desconocido.";
      sevMes = "warn"
      console.debug(message);
      break;
  }

  this.messages.add({ severity: sevMes, summary: "Catálogo de Articulos.", detail: messText });
}

/* populate() {
  let filter = {} as MovimientoAlmacenReg;

  this.virtualScroll = false;
  this.psSalida.find(filter).subscribe(
    {
      next: (data) => {
        this.catalog = data as MovimientoAlmacenReg[];
        if (this.catalog.length > 10) {
          this.virtualScroll = true;
        }
      },
      error: (error) => {
        try {

          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
      }
    }
  );
} */

onCancelar($event){
  this.visible=false;
}

onGuardar(event){
  if(/* this.item.idProyecto && */ this.item.idEmpleado && this.item.comentario) {
    this.item.idArticulo = this.idArticulo;
    this.successMessage("Listo", "Se le dio salida");
    this.psSalida.registrarSalida(this.item).subscribe(
      (data) => {
        this.onHideSalida.emit(true);
      },
      (error)=>{
        console.debug("No se asigno la herramienta: ");
        console.debug(error);
      }
    )

  }
  else {
    this.infoMessage("Atencion", "Campos faltantes");
  }
  /* this.clientMov.register(this.item).subscribe(
    {
      next: (data) => {
        this.populate();
        this.showForm = false;
        this.newItem = undefined;
      },
      error: (error) => {
        try {
          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
        this.showForm = false;
        this.newItem = undefined;
      }
    }); */
}
}
