import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { ProyectosRestcService } from '../client/proyectos-restc.service'; 
@Injectable({
  providedIn: 'root'
})
export class ProyectosPsService extends BaseProcess<ProyectosRestcService>{

  client: ProyectosRestcService;

  constructor(httpClient: HttpClient, crest: ProyectosRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }
}