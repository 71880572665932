<br><br>

<div class="grid frm-part">

    <!-- {{item | json}} -->
    <div class="col-10 sm:col-12 md:col-6 lg:col-4 xl:col-10">
        <br>
        <span class="p-float-label">
            <input id="fl-comentario" type="text" pInputText [(ngModel)]="item.comentario">
            <label for="fl-comentario">Comentario</label>
        </span>
    </div>
    <div class="col-10 sm:col-12 md:col-6 lg:col-4 xl:col-10">
        <br>
        <span class="p-float-label">
            <input id="fl-cantidad" type="text" pInputText [(ngModel)]="item.cantidad">
            <label for="fl-cantidad">Cantidad</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <p-button label="Guardar" (click)="onGuardar($event)"></p-button>&nbsp;
        <!-- <p-button label="Cancelar" (click)="onCancelar($event)"></p-button> -->
    </div>

</div>