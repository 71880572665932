import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { MarcaRestcService } from '../client/marca-restc.service';

@Injectable({
  providedIn: 'root'
})
export class MarcaPsService extends BaseProcess<MarcaRestcService> {

  constructor(httpClient: HttpClient, crest: MarcaRestcService) {
    super(httpClient, crest);
   }
}