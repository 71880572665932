import packageInfo from '../../package.json';

export const environment = {
    version: packageInfo.version,
    production: false,
    dev: true,
    uat: false,
    /* portal: "http://dev.habitataxkan.com.mx", */
    /* basePath: "/tools/", */
    appId: "bodega-gui",
    empresas: [
        { id: 1, domain: 'dev.axkansoluciones.com' },
        { id: 2, domain: 'dev.sbsynergy.mx' },
        { id: 3, domain: 'dev.habitataxkan.com.mx' }
    ],
    sprofile: "https://sec.dev-ms.axkans.net/autentica/profile",
    /* sprofile: "http://localhost:8080/autentica/profile", */
    client: {
        msUnidadMedida: 'https://bodega.dev-ms.axkans.net/bodega/bodega/unidad-medida',
        msTipoMaterial: 'https://bodega.dev-ms.axkans.net/bodega/bodega/tipo-material',
        msPropiedad: 'https://bodega.dev-ms.axkans.net/bodega/bodega/propiedad',
        msProyecto: 'https://bodega.dev-ms.axkans.net/bodega/planeacion/proyecto',
        msProyectodev: 'http://proyecto.dev-ms.axkans.net/planeacion/proyecto',
        msTipoArticulo: 'https://bodega.dev-ms.axkans.net/bodega/bodega/tipo-articulo',
        msCategoria: 'https://bodega.dev-ms.axkans.net/bodega/bodega/categoria-articulo',
        msMarca: 'https://bodega.dev-ms.axkans.net/bodega/bodega/marca',
        msArticulo: 'https://bodega.dev-ms.axkans.net/bodega/bodega/articulo',
        msTipoMovimiento: 'https://bodega.dev-ms.axkans.net/bodega/bodega/tipomovimiento',
        msMovimientoAlmacen: 'https://bodega.dev-ms.axkans.net/bodega/bodega/movimiento-almacen',
        msEstadoArticulo: 'https://bodega.dev-ms.axkans.net/bodega/bodega/estado-articulo',
        msEmpleadoPs: "https://rh.dev-ms.axkans.net/rh/empleado/ps"
    },
    theme: {
        default: 'https://static.dev-gui.axkans.org/portal/themes/blue-gray-1.0.0/styles/theme.css'

    },

    //Variables de entorno
    pzRegister: 1

    //default: 'http://static.axkans.loc/blue-gray-1.0.0/styles/theme.css'

};
