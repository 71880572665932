import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MovimientoAlmacenReg } from '../api/articulo';
import { DetalleHistorico } from '../api/articulo';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MovimientoalmacenRestcService extends ARestClient{

  constructor(protected client:HttpClient) { 
    super(client);
  }
  getBaseEndpoint(): string {
    return environment.client.msMovimientoAlmacen+"/";
  }
  processBody(res: any) {
    return res || {};
  }
  getRestPaths(): RestBasicPaths{
    let restPaths = super.getRestPaths();
    restPaths.detail='';
    restPaths.remove='';
    return restPaths;
  }

  registrarSalida(item: MovimientoAlmacenReg) {
    let path = this.getBaseEndpoint() + "salida";

    return this.httpClient.post(path, item);
  }

  registrarEntrada(item: MovimientoAlmacenReg) {
    let path = this.getBaseEndpoint() + "entrada";

    return this.httpClient.post(path, item);
  }

  historico(idArticulo: number): Observable<DetalleHistorico[]> {
    let path = this.getBaseEndpoint() + "historico/" + idArticulo;

    return this.httpClient.get(path) as Observable<DetalleHistorico[]>
  }


}
