import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Marca } from 'src/app/api/articulo';
import { MarcaPsService } from 'src/app/procs/marca-ps.service';

@Component({
  selector: 'axks-marca',
  templateUrl: './marca.component.html',
  styleUrls: ['./marca.component.scss']
})
export class MarcaComponent extends BaseMainComponent<MarcaPsService> implements OnInit{
  

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    protected marcaPs: MarcaPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }


  initEmptyItem(): ComponentItem {
    return {
      item: {} as Marca,
      minimumItem: {} as Marca,
      registerItem: {} as Marca
    } as ComponentItem;
  }
  getService(): MarcaPsService {
    return this.marcaPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {field: "id", header: "Clave", inAll:true},
      {field: "marca", header:"Marca", inAll:true}
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Marca";
  }
}
