import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { OperationMode } from '@axks/components';
import { Articulo } from 'src/app/api/articulo';
@Component({
  selector: 'axks-material-form',
  templateUrl: './material-form.component.html',
  styleUrls: ['./material-form.component.scss']
})
export class MaterialFormComponent extends BaseView implements OnInit{

  @Input() item: Articulo;
  @Output() onChange = new EventEmitter<number>();
  virtualScroll: boolean = false;
  esBodega: boolean = false;
  @Input() addNewEnable: boolean = false;
  showForm: boolean = false;
  //@Input() idCategoria: Number=1;
  OPERATIONMODE = OperationMode;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }


  ngOnInit(): void {
    if (!this.item)
      this.item = {
        idCategoria: 1
      };
  }

  selMarca(event) {
    this.item.idMarca = event;
  }

  selTipoArticulo(event) {
    this.item.idTipoArticulo = event;
  }

  changeUnidadMedida(event){
    this.item.idUnidadMedida = event;
  }
}
