import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { CategoriaRestcService } from '../client/categoria-restc.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriaPsService extends BaseProcess<CategoriaRestcService> {

  constructor(httpClient: HttpClient, crest: CategoriaRestcService) {
    super(httpClient, crest);
   }
}