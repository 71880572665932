<!-- {{item | json}}-->
<br>
<span class="p-float-label" [style.width]="(addNewEnable && !showForm) ? '22%': '70%'"
[style.float]="(addNewEnable && !showForm) ? 'left': ''">
    <p-dropdown inputId="fl-marca" [(ngModel)]="item.id" [options]="catalog" optionLabel="marca"
        optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10" [filter]="true"
        filterBy="marca" autoWidth="false" [style]="{'width':'80%'}" showClear="true"
        (onChange)="selectOption($event)" [baseZIndex]="10002" appendTo="body"></p-dropdown>
    <label for="fl-marca">Marca</label>
</span>
<p-button icon="pi pi-plus" iconPos="left" *ngIf="addNewEnable && !showForm" (click)="onAddNewClick($event)">
</p-button>


<!-- <p-dialog [(visible)]="showForm" [modal]="true" style="height: 100px;" [draggable]="false" [resizable]="false"
    [baseZIndex]="30000" [contentStyleClass]="'dialog-content'"> -->
<p-panel class="cat-form-popup" header="Nueva Marca" *ngIf="showForm">
<!--     <p-dialog header="Nueva marca" [(visible)]="showForm" [modal]="true" [style]="{ width: '35vw', height: '250px', 'top':'5vh' }" [draggable]="false" [resizable]="false"
    [baseZIndex]="50000" [contentStyleClass]="'dialog-content'"> -->
    <axks-marca-form [item]="newItem" [formFields]="[]" [operationMode]="operationMode">
    </axks-marca-form>
    <ng-template pTemplate="footer">

        <p-button label="Guardar" icon="pi pi-save" iconPos="left" (click)="onSaveClick($event)"></p-button>
        &nbsp;
        <p-button label="Cancelar" icon="pi pi-times" iconPos="left" (click)="onCancelClick($event)"></p-button>
    </ng-template>
    <!-- </p-dialog> -->
</p-panel>
