import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Propiedad } from 'src/app/api/articulo';
import { PropiedadPsService } from 'src/app/procs/propiedad-ps.service';

@Component({
  selector: 'axks-propiedad',
  templateUrl: './propiedad.component.html',
  styleUrls: ['./propiedad.component.scss']
})
export class PropiedadComponent  extends BaseMainComponent<PropiedadPsService> implements OnInit{
  

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    protected propiedadPs: PropiedadPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }


  initEmptyItem(): ComponentItem {
    return {
      item: {} as Propiedad,
      minimumItem: {} as Propiedad,
      registerItem: {} as Propiedad
    } as ComponentItem;
  }

  getService(): PropiedadPsService {
    return this.propiedadPs;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      {field: "id", header: "Clave", inAll:true},
      {field: "propiedad", header:"Característica", inAll:true}
    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  
  getComponentTitle(): string {
    return "Propiedad";
  }
}
