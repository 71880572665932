<br><br>

<div class="grid frm-part">
    <!-- {{item | json}} -->
<!--     <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4">
        <axks-proyecto-cat [value]="item.idProyecto" [addNewEnable]="true" (onChange)="selProyecto($event)"
            [operationMode]="operationMode"></axks-proyecto-cat>
    </div> -->

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-idempleado" [(ngModel)]="item.idEmpleado" [options]="empleadosNombre" (onChange)="selectOption($event)"
                [value]="item.idEmpleado" optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false"
                [virtualScroll]="virtualScroll" [virtualScrollItemSize]="10" filter="true" filterBy="nombreCompleto"
                autoWidth="false" [baseZIndex]="20000" appendTo="body" [showClear]="true"
                [style]="{'width': '100%'}"></p-dropdown>
            <label for="fl-idempleado">Asignado A</label>
        </span>
    </div>

<!--     <span class="p-float-label" [style.width]="(addNewEnable && !showForm) ? '100%': '90%'"
    [style.float]="(addNewEnable && !showForm) ? 'left': ''">
    <br>
    <p-dropdown inputId="fl-estados" [(ngModel)]="item.idEstadoArticulo" [options]="estados"
        optionLabel="estadoArticulo" optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll"
        itemSize="10" [filter]="true" filterBy="estadoArticulo" autoWidth="false" [style]="{'width':'21%'}"
        showClear="true" (onChange)="selectEstado($event)" [baseZIndex]="10002" appendTo="body"></p-dropdown>
    <label for="fl-estados">Estado de la herramienta</label>
</span> -->

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4">
        <br>
        <span class="p-float-label">
            <input id="fl-comentario" type="text" pInputText [(ngModel)]="item.comentario">
            <label for="fl-comentario">Comentario</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <p-button label="Guardar" (click)="onGuardar($event)"></p-button>&nbsp;
        <!-- <p-button label="Cancelar" (click)="onCancelar($event)"></p-button> -->
    </div>

</div>