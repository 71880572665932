import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoMaterialRestcService } from '../client/tipo-material-restc.service';

@Injectable({
  providedIn: 'root'
})
export class TipoMaterialPsService extends BaseProcess<TipoMaterialRestcService>{

  constructor(httpClient: HttpClient, crest: TipoMaterialRestcService) {
    super(httpClient, crest);
   }
}