import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Articulo, ArticuloReg } from 'src/app/api/articulo';
import { ArticuloPsService } from 'src/app/procs/articulo-ps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-articulo',
  templateUrl: './articulo.component.html',
  styleUrls: ['./articulo.component.scss']
})
export class ArticuloComponent extends BaseMainComponent<ArticuloPsService> implements OnInit, AfterViewInit {
  extraButtons: ButtonModel[];
  showDialog: boolean = false;
  showMantenimiento: boolean = false;
  showEntrada: boolean = false;
  showSalida: boolean = false;
  esMantenimiento: boolean= false;
  esSalida: boolean = false;
  esEntrada: boolean = false;
  esHistorico: boolean = false;
  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    protected articuloPs: ArticuloPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    /* alert(this.deviceService.isMobile()) */
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

  }

  showFilterButton() {
    return this.operationMode == OperationMode.SEARCH;
  }


  initEmptyItem(): ComponentItem {
    return {
      item: {
        idCategoria: 2,
      } as Articulo,
      minimumItem: {} as Articulo,
      registerItem: {
        proyectoInterno: false,
        idCategoria: 2,
        cantidad: environment.pzRegister
      } as ArticuloReg
    } as ComponentItem;
  }
  getService(): ArticuloPsService {
    return this.articuloPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      { field: "idArticulo", header: "Clave", inAll: true },
      /* { field: "categoria", header: "Categoría", inAll: true }, */
      /* {field: "idCategoria", header:"Categoría", inAll:true}, */
      { field: "tipoArticulo", header: "Tipo de Herramienta", inAll: true },
      { field: "marca", header: "Marca", inAll: true },
      /* { field: "proyecto", header: "Adquirido por", inAll: true }, */
      { field: "estadoArticulo", header: "Estado del articulo", inAll: true },
      { field: "disponibles", header: "Disponibles", inAll: true },
      /* { field: "totalDisponibles", header: "Total", inAll: true }, */
      { field: "asignado", header: "Se encuentra asignado", inAll: true, formatFunction: this.formatBoolean},
      /* { field: "idCompra", header: "Compra", inDetail: false, }, */
     /*  { field: "funcional", header: "Funcional", inDetail: true, formatFunction: this.formatBoolean }, */
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.idArticulo || this.item.item.id || this.item.registerItem.id;
  }
  getComponentTitle(): string {
    /* return "Artículo"; */
    return "Herramienta";
  }

  componentsFields(): void {
    super.componentsFields();
  }

  formatBoolean(value) {
    let valueBool = value as Boolean;
    return valueBool == true ? 'Sí' : 'No';
  }

  initExtraButtons(herramientaOcupada: boolean) {
    if (herramientaOcupada == true) {
      this.extraButtons = [
        {
          name: "btnEntrada", enable: true, label: "Entrada", showLabel: true, showWhen: [OperationMode.SELECTED], position: 'left', onClick: this.entradaClic.bind(this)
        },
        {
          name: "btnHistorico", enable: true, label: "Historico", showLabel: true, showWhen: [OperationMode.SELECTED], position: 'left', onClick: this.historicoClic.bind(this)
        },
        {
          name: "btnMantenimieno", enable: true, label: "Necesita mantenimiento", showLabel: true, showWhen: [OperationMode.SELECTED], position: 'left', onClick: this.mantenimientoClic.bind(this)
        }
      ];
    }
    else if (herramientaOcupada == false) {
      this.extraButtons = [
        {
          name: "btnSalida", enable: true, label: "Salida", showLabel: true, showWhen: [OperationMode.SELECTED], position: 'left', onClick: this.salidaClic.bind(this)
        },
        {
          name: "btnHistorico", enable: true, label: "Historico", showLabel: true, showWhen: [OperationMode.SELECTED], position: 'left', onClick: this.historicoClic.bind(this)
        }

      ];
    }
    else {
      this.extraButtons = [];
    }
  }

  salidaClic() {
    this.showSalida = true;
    this.esEntrada = false;
    this.esSalida = true;
  }

  entradaClic() {
    this.showEntrada = true;
    this.esSalida = false;
    this.esEntrada = true;
  }

  mantenimientoClic(){
    this.showMantenimiento = true;
    this.esSalida = false;
    /* this.esEntrada = true; */ 
    this.esMantenimiento = true;
  }

  rowSelect(event: any): void {
    super.rowSelect(event);
    this.extraButtons = [];
    setTimeout(() => {
      this.initExtraButtons(this.item.item.esOcupada);
    }, 2000);
  }

  historicoClic() {
    this.showDialog = true;
    this.esHistorico = true;
    console.log(this.workItem())
  }

  hideDialogSalida(event) {
    this.showSalida = false;
    this.rowSelect(this.workItem());
  }
  hideDialogEntrada(event) {
    this.showEntrada = false;
    this.rowSelect(this.workItem());
  }
  hideDialogMantenimiento(event) {
    this.showMantenimiento = false;
    this.rowSelect(this.workItem());
  }

}
