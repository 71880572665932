import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoMaterial, UnidadMedida } from 'src/app/api/articulo';
import { TipoMaterialPsService } from 'src/app/procs/tipo-material-ps.service';
import { UnidadMedidaPsService } from 'src/app/procs/unidad-medida-ps.service';
@Component({
  selector: 'axks-tipo-material',
  templateUrl: './tipo-material.component.html',
  styleUrls: ['./tipo-material.component.scss']
})
export class TipoMaterialComponent extends BaseMainComponent<TipoMaterialPsService> implements OnInit{

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    protected tipoMaterialPs: TipoMaterialPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
   }
   ngOnInit(): void {
    super.ngOnInit();
  }


  initEmptyItem(): ComponentItem {
    return {
      item: {unidadMedida:{}} as TipoMaterial,
      minimumItem: {unidadMedida:{}} as TipoMaterial,
      registerItem: {} as TipoMaterial
    } as ComponentItem;
  }
  getService(): UnidadMedidaPsService {
    return this.tipoMaterialPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {field: "id", header: "Clave", inAll:true},
      {field: "tipoMaterial", header: "Tipo de Material", inAll:true},
      {field: "unidadMedida.unidadMedida", header:"Unidad de medida", inAll:true}
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Material";
  }
}
