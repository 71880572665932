import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RestClientModule } from '@axks/net';
import { ActionsBarModule, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ToolbarModule } from 'primeng/toolbar';
import { ToastModule } from 'primeng/toast';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

import localeESMX from "@angular/common/locales/es-MX";
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { TipoArticuloFormComponent } from './views/tipo-articulo/tipo-articulo-form/tipo-articulo-form.component';
import { TipoArticuloComponent } from './views/tipo-articulo/tipo-articulo/tipo-articulo.component';
import { UnidadMedidaFormComponent } from './views/unidad-medida/unidad-medida-form/unidad-medida-form.component';
import { UnidadMedidaComponent } from './views/unidad-medida/unidad-medida/unidad-medida.component';
import { UnidadMedidaCatComponent } from './views/unidad-medida/unidad-medida-cat/unidad-medida-cat.component';
import { CategoriaComponent } from './views/categoria/categoria/categoria.component';
import { CategoriaFormComponent } from './views/categoria/categoria-form/categoria-form.component';
import { CategoriaCatComponent } from './views/categoria/categoria-cat/categoria-cat.component';
import { MarcaComponent } from './views/marca/marca/marca.component';
import { MarcaFormComponent } from './views/marca/marca-form/marca-form.component';
import { MarcaCatComponent } from './views/marca/marca-cat/marca-cat.component';
import { TipoArticuloCatComponent } from './views/tipo-articulo/tipo-articulo-cat/tipo-articulo-cat.component';
import { PropiedadComponent } from './views/propiedad/propiedad/propiedad.component';
import { PropiedadFormComponent } from './views/propiedad/propiedad-form/propiedad-form.component';
import { PropiedadCatComponent } from './views/propiedad/propiedad-cat/propiedad-cat.component';
import { ArticuloComponent } from './views/articulo/articulo/articulo.component';
import { ArticuloFormComponent } from './views/articulo/articulo-form/articulo-form.component';
import { ProyectoCatComponent } from './views/proyecto/proyecto-cat/proyecto-cat.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { TimelineModule } from 'primeng/timeline';
import { TreeTableModule } from 'primeng/treetable';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TipomovimientoFormComponent } from './views/tipomovimiento/tipomovimiento-form/tipomovimiento-form.component';
import { TipomovimientoComponent } from './views/tipomovimiento/tipomovimiento/tipomovimiento.component';
import { TipomovimientoCatComponent } from './views/tipomovimiento/tipomovimiento-cat/tipomovimiento-cat.component';
import { MovimientoAlmacenFormComponent } from './views/movimiento-almacen/movimiento-almacen-form/movimiento-almacen-form.component';
import { MovimientoAlmacenComponent } from './views/movimiento-almacen/movimiento-almacen/movimiento-almacen.component';
import { ArticuloCatComponent } from './views/articulo/articulo-cat/articulo-cat.component';
import { CalendarModule } from 'primeng/calendar';
import { ArticuloSalidaFormComponent } from './views/articulo/articulo-salida-form/articulo-salida-form.component';
import { ArticuloEntradaFormComponent } from './views/articulo/articulo-entrada-form/articulo-entrada-form.component';
import { DialogModule } from 'primeng/dialog';
import { HistoricoFormComponent } from './views/articulo/historico-form/historico-form.component';
import { HomeComponent } from './views/home/home.component';
import { TipoMaterialFormComponent } from './views/tipo-material/tipo-material-form/tipo-material-form.component';
import { TipoMaterialComponent } from './views/tipo-material/tipo-material/tipo-material.component';
import { TipoMaterialCatComponent } from './views/tipo-material/tipo-material-cat/tipo-material-cat.component';
import { MaterialFormComponent } from './views/material/material-form/material-form.component';
import { MaterialComponent } from './views/material/material/material.component';
import { MaterialEntradaFormComponent } from './views/material/material-entrada-form/material-entrada-form.component';
import { MaterialSalidaFormComponent } from './views/material/material-salida-form/material-salida-form.component';
import { MaterialHistoricoFormComponent } from './views/material/material-historico-form/material-historico-form.component';
import { MantenimientoFormComponent } from './views/articulo/mantenimiento-form/mantenimiento-form.component';
registerLocaleData(localeESMX, "es-MX"); 


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    TipoArticuloFormComponent,
    TipoArticuloComponent,
    UnidadMedidaFormComponent,
    UnidadMedidaComponent,
    UnidadMedidaCatComponent,
    CategoriaComponent,
    CategoriaFormComponent,
    CategoriaCatComponent,
    MarcaComponent,
    MarcaFormComponent,
    MarcaCatComponent,
    TipoArticuloCatComponent,
    PropiedadComponent,
    PropiedadFormComponent,
    PropiedadCatComponent,
    ArticuloComponent,
    ArticuloFormComponent,
    ProyectoCatComponent,
    TipomovimientoFormComponent,
    TipomovimientoComponent,
    TipomovimientoCatComponent,
    MovimientoAlmacenFormComponent,
    MovimientoAlmacenComponent,
    ArticuloCatComponent,
    ArticuloSalidaFormComponent,
    ArticuloEntradaFormComponent,
    HistoricoFormComponent,
    HomeComponent,
    TipoMaterialFormComponent,
    TipoMaterialComponent,
    TipoMaterialCatComponent,
    MaterialFormComponent,
    MaterialComponent,
    MaterialEntradaFormComponent,
    MaterialSalidaFormComponent,
    MaterialHistoricoFormComponent,
    MantenimientoFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RestClientModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToolbarModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailTabModule,
    ToastModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    PanelModule,
    CheckboxModule,
    OverlayPanelModule,
    AvatarModule,
    CalendarModule,
    ProgressSpinnerModule,
    TimelineModule,
    CardModule,
    TreeTableModule, 
    DialogModule
  ],
  providers: [MessageService, SessionClientProvider,
    { provide: "env", useValue: environment }],
  bootstrap: [AppComponent]
})
export class AppModule { }
