import { Component } from '@angular/core';
import { AppClient } from '@axks/components';

@Component({
  selector: 'axks-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AppClient{
  title = 'habitat-bodega-gui';
}
