<div class="grid frm-part">
    <!-- {{item | json}} -->
    
    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-12" *ngIf="allowFormFields('idTipoArticulo')">
        <axks-tipo-articulo-cat [item]="{id:item.idTipoArticulo}" [addNewEnable]="true"
            (onChange)="selTipoArticulo($event)" [operationMode]="operationMode"
            [esHerramienta]="false"></axks-tipo-articulo-cat>
    </div>


    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('idUnidadMedida')">
        <axks-unidad-medida-cat [value]="item.unidadMedida" [addNewEnable]="true" (onChange)="changeUnidadMedida($event)"></axks-unidad-medida-cat>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4">
        <br>
        <span class="p-float-label">
            <input id="fl-cantidad" type="text" pInputText [(ngModel)]="item.cantidad">
            <label for="fl-cantidad">Cantidad</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-12" *ngIf="allowFormFields('marca')">
        <axks-marca-cat [item]="{id:item.idMarca}" [addNewEnable]="true" (onChange)="selMarca($event)"
            [operationMode]="operationMode"></axks-marca-cat>
    </div>

</div>