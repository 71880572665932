import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MovimientoAlmacen, TipoMovimiento } from 'src/app/api/articulo';
import { MovimientoalmacenPsService } from 'src/app/procs/movimientoalmacen-ps.service';
import { OperationMode } from '@axks/components';
import { EmpleadosPsService } from 'src/app/procs/empleados-ps.service';
@Component({
  selector: 'axks-movimiento-almacen-form',
  templateUrl: './movimiento-almacen-form.component.html',
  styleUrls: ['./movimiento-almacen-form.component.scss']
})
export class MovimientoAlmacenFormComponent extends BaseView implements OnInit{
  OPERATIONMODE = OperationMode;
  @Input() item: MovimientoAlmacen;
  @Input() operationMode: OperationMode;
  @Input() value?: MovimientoAlmacen
  @Output() onChange = new EventEmitter<number>();
  @Input() addNewEnable: boolean;
  empleadosNombre: any[];
  showForm: boolean = false;
  catTipoMovimiento: TipoMovimiento[];
  screenSubs: Subscription;
  virtualScroll: boolean = false;

  constructor(screenModeService: OperationScreenModeService,
    deviceService: DeviceDetectorService,
    messageService: MessageService,
    private psMovAlmacen: MovimientoalmacenPsService,
    private clientEmp: EmpleadosPsService,
    sessionProvider: SessionClientProvider,
    ) {
    super(screenModeService, deviceService, messageService, sessionProvider);
    this.screenSubs = this.screenModeService.screenMode.subscribe((data) => {
      this.operationMode = data;
      switch (this.operationMode) {
        case OperationMode.ADDNEW:
        case OperationMode.EDIT:
        case OperationMode.SEARCH:
          break;
      }
    })
  }
  ngOnInit(): void {
    if(!this.item) 
      this.item = { };

      this.initEmpleadosNombre();
  }


  selectTipo(event) {
    this.item.idTipoMovimiento = event;
  }
  
  selectOption(event) {
    this.onChange.emit(event.value);
  }

  selProyecto(event) {
    this.item.idProyecto = event;
  }

  selectArticulo(event) {
    this.item.idArticulo = event;
  }

  initEmpleadosNombre() {  //lo acabo de agregar
    this.clientEmp.empleadosActivos(1).subscribe(
      (data) => {
        this.empleadosNombre = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo cargar el catalogo de empleados");
      }
    );
  }
}
