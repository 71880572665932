<!-- Formulario modo registro -->
<div class="grid frm-part">
    <!-- {{item | json}} -->
<!--         <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-12" *ngIf="allowFormFields('articulo')">
        <span class="p-float-label">
            <input id="fl-articulo" type="text" pInputText [(ngModel)]="item.articulo">
            <label for="fl-articulo">Nombre</label>
        </span>
    </div> -->
<!--     <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-12" *ngIf="allowFormFields('categoria')">
        <axks-categoria-cat [item]="{'id':item.idCategoria}" [addNewEnable]="true" (onChange)="selCategoria($event)"
            [operationMode]="operationMode"></axks-categoria-cat>
    </div> -->
    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-12" *ngIf="allowFormFields('tipoArticulo')">
        <axks-tipo-articulo-cat [item]="{id:item.idTipoArticulo}" [addNewEnable]="true"
            (onChange)="selTipoArticulo($event)" [operationMode]="operationMode"
            [esHerramienta]="true"></axks-tipo-articulo-cat>
    </div>
    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-12">
        <span class="p-float-label" [style.width]="(addNewEnable && !showForm) ? '100%': '90%'"
        [style.float]="(addNewEnable && !showForm) ? 'left': ''">
        <br>
        <p-dropdown inputId="fl-estados" [(ngModel)]="item.idEstadoArticulo" [options]="estados"
            optionLabel="estadoArticulo" optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll"
            itemSize="10" [filter]="true" filterBy="estadoArticulo" autoWidth="false" [style]="{'width':'30%'}"
            showClear="true" (onChange)="selectOption($event)" [baseZIndex]="10002" appendTo="body"></p-dropdown>
        <label for="fl-estados">Estado de la herramienta</label>
    </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-12" *ngIf="allowFormFields('marca')">
        <axks-marca-cat [item]="{id:item.idMarca}" [addNewEnable]="true" (onChange)="selMarca($event)"
            [operationMode]="operationMode"></axks-marca-cat>
    </div>

    <!--     <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-12" *ngIf="allowFormFields('idCompra')">
        <span class="p-float-label">
            <input id="fl-compra" type="text" pInputText [(ngModel)]="item.idCompra">
            <label for="fl-compra">Compra</label>
        </span>
    </div> -->
    <!--     El id de la compra no se muestra solo se queda el registro para nosotros
    Adquirido por: bodega o proyecto (Solo deben de mostrarse los proyectos activos)
    Estado de la herramienta: Funcional, Por reparar, Desechable(El estado deberia ser más bien en un modal)
    Asignado a: Israel que esta en el proyecto: Calle 1
    Historico restringir solo los ultimos 5 movimientos -->

<!--     <div class="col-6 sm:col-12 md:col-6 lg:col-6 xl:col-12" *ngIf="allowFormFields('proyectoInterno')">
        <label for="proyectointerno">Es adquirido por bodega(proyecto interno)</label>
        <p-checkbox [(ngModel)]="item.proyectoInterno" [binary]="true" inputId="proyectointerno"
            (onChange)="esActivo($event)"></p-checkbox>
    </div> -->

    <!--     <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-12" *ngIf="allowFormFields('idProyecto')">
        <axks-proyecto-cat [item]="{id:item.idProyecto}" [addNewEnable]="true" [esBodega]="esBodega"
        (onChange)="selProyecto($event)" [operationMode]="operationMode"></axks-proyecto-cat>
    </div> -->

<!--     <span class="p-float-label" [style.width]="(addNewEnable && !showForm) ? '100%': '90%'"
        [style.float]="(addNewEnable && !showForm) ? 'left': ''">
        <br>
        <p-dropdown inputId="fl-proyectos" [(ngModel)]="item.idProyecto" [options]="proyectos" optionLabel="proyecto"
            optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10" [filter]="true"
            filterBy="proyecto" autoWidth="false" [style]="{'width':'20%'}" showClear="true" [disabled]="esBodega"
            [baseZIndex]="10002" appendTo="body"></p-dropdown>
        <label for="fl-proyectos">Adquirido por</label>
    </span> -->

<!--     <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('asignado')">
        <span class="p-float-label input-check-gp">
            <p-checkbox [binary]="true" [(ngModel)]="item.buscaDisponibles" (onChange)="marcaDisponibles($event)">
            </p-checkbox>
            <label for="fl-disponibles">Disponibles</label>
        </span>
    </div> -->

</div>