import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageCodes, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { TipoMovimiento } from 'src/app/api/articulo';
import { TipomovimientoPsService } from 'src/app/procs/tipomovimiento-ps.service';

@Component({
  selector: 'axks-tipomovimiento-cat',
  templateUrl: './tipomovimiento-cat.component.html',
  styleUrls: ['./tipomovimiento-cat.component.scss']
})
export class TipomovimientoCatComponent implements OnInit, OnDestroy, OnChanges{
  @Input() value?: TipoMovimiento;
  @Input() operationMode: OperationMode;
  @Input() addNewEnable: boolean;
  @Input() item?: TipoMovimiento;
  @Output() onChange = new EventEmitter<number>();

  catalog: TipoMovimiento[];
  virtualScroll: boolean = false;
  showForm: boolean = false;
  newItem: TipoMovimiento;

  constructor(private psTipoMovimiento: TipomovimientoPsService,
    private messages: MessageService,
    private route: ActivatedRoute) {

  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {} as TipoMovimiento;
    }
    if (this.operationMode) {
      this.enableAddNewButton();
    }

    // Temporal mientras se desarrolla
    this.route.data.subscribe((data) => {
      this.addNewEnable = data.addNewEnable;
      this.populate();
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
        this.addNewEnable = true;
        break;
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {} as TipoMovimiento;

    this.virtualScroll = false;
    this.psTipoMovimiento.find(filter).subscribe(
      {
        next: (data) => {
          this.catalog = data as TipoMovimiento[];
          if (this.catalog.length > 10) {
            this.virtualScroll = true;
          }
        },
        error: (error) => {
          try {

            let message = error.error as Message;
            this.processMessage(message);
          } catch (e) {
            console.debug("Error")
            console.debug(error)
          }
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron las Características. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo de Características.", detail: messText });
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = {} as TipoMovimiento;
  }

  onSaveClick(event) {
    this.psTipoMovimiento.register(this.newItem).subscribe(
      {
        next: (data) => {
          this.populate();
          this.showForm = false;
          this.newItem = undefined;
        },
        error: (error) => {
          try {
            let message = error.error as Message;
            this.processMessage(message);
          } catch (e) {
            console.debug("Error")
            console.debug(error)
          }
          this.showForm = false;
          this.newItem = undefined;
        }
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = undefined;
  }

  selectOption(event) {
    this.onChange.emit(this.item.id);
  }

}
