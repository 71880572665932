import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Categoria } from 'src/app/api/articulo';
import { CategoriaPsService } from 'src/app/procs/categoria-ps.service';

@Component({
  selector: 'axks-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent extends BaseMainComponent<CategoriaPsService> implements OnInit{
  

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    protected categoriaPs: CategoriaPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }


  initEmptyItem(): ComponentItem {
    return {
      item: {} as Categoria,
      minimumItem: {} as Categoria,
      registerItem: {} as Categoria
    } as ComponentItem;
  }
  getService(): CategoriaPsService {
    return this.categoriaPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {field: "id", header: "Clave", inAll:true},
      {field: "categoria", header:"Categoría del artículo", inAll:true}
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Categoría";
  }
}
