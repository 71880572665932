<br><br>

<div class="grid frm-part">

    <!-- {{item | json}} -->
<!--      <span class="p-float-label" [style.width]="(addNewEnable && !showForm) ? '100%': '90%'"
    [style.float]="(addNewEnable && !showForm) ? 'left': ''">
    <br> 
     <p-dropdown inputId="fl-estados" [(ngModel)]="item.idEstadoArticulo" [options]="estados"
        optionLabel="estadoArticulo" optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll"
        itemSize="10" [filter]="true" filterBy="estadoArticulo" autoWidth="false" [style]="{'width':'21%'}"
        showClear="true" (onChange)="selectOption($event)" [baseZIndex]="10002" appendTo="body"></p-dropdown>
    <label for="fl-estados">Estado de la herramienta</label>
</span>  -->

<!--         <div class="col-6 sm:col-12 md:col-6 lg:col-6 xl:col-12">
        <label for="estadoRegreso">Se encuentra en optimas condiciones</label>
        <p-checkbox [(ngModel)]="item.estadoRegreso" [binary]="true" inputId="estadoRegreso"
            (onChange)="esFuncional($event)"></p-checkbox>
    </div> -->

    <div class="col-6 sm:col-12 md:col-6 lg:col-6 xl:col-12">
        <label for="estadoRegreso">Se encuentra en optimas condiciones</label>
        <p-checkbox [(ngModel)]="item.estadoRegreso" [binary]="true" inputId="estadoRegreso"></p-checkbox>
    </div>

<!--     <div class="col-6 sm:col-12 md:col-6 lg:col-6 xl:col-12">
        <label for="mantenimiento">Requiere mantenimiento</label>
        <p-checkbox [(ngModel)]="item.mantenimiento" [binary]="true" inputId="mantenimiento"
            (onChange)="esMantenimiento($event)"></p-checkbox>
    </div> -->

    <div class="col-10 sm:col-12 md:col-6 lg:col-4 xl:col-10">
        <br>
        <span class="p-float-label">
            <input id="fl-comentario" type="text" pInputText [(ngModel)]="item.comentario">
            <label for="fl-comentario">Comentario</label>
        </span>
    </div>
    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <p-button label="Guardar" (click)="onGuardar($event)"></p-button>&nbsp;
        <!-- <p-button label="Cancelar" (click)="onCancelar($event)"></p-button> -->
    </div>

</div>