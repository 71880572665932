import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { OperationMode } from '@axks/components';
import { Articulo } from 'src/app/api/articulo';
import { EstadoArticulo } from 'src/app/api/articulo';
import { MovimientoAlmacenReg } from 'src/app/api/articulo';
import { EmpleadosPsService } from 'src/app/procs/empleados-ps.service';
import { MovimientoalmacenRestcService } from 'src/app/client/movimientoalmacen-restc.service';
import { MovimientoalmacenPsService } from 'src/app/procs/movimientoalmacen-ps.service';
import { EstadoarticuloPsService } from 'src/app/procs/estado-articulo-ps.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'axks-articulo-entrada-form',
  templateUrl: './articulo-entrada-form.component.html',
  styleUrls: ['./articulo-entrada-form.component.scss']
})
export class ArticuloEntradaFormComponent extends BaseView implements OnInit{
  @Input() item: MovimientoAlmacenReg;
@Output() onChange = new EventEmitter<number>();
@Output() onHide = new EventEmitter<number>();
@Input() visible: boolean = false;
virtualScroll: boolean = false;
@Input() addNewEnable: boolean = false;
funciona: boolean = false;
disponible: boolean = true;
@Input() idArticulo: number;
display: boolean = false;
showForm: boolean = false;
estados: EstadoArticulo[];
@Output() onHideEntrada = new EventEmitter<boolean>();
/* virtualScroll: boolean = false;
empleadosNombre: any[]; */
constructor(protected screenModeService: OperationScreenModeService, 
  protected deviceService: DeviceDetectorService, 
  protected messageService: MessageService,
  private clientEmp: EmpleadosPsService, 
  private psEntrada: MovimientoalmacenPsService,
  private clientEstado: EstadoarticuloPsService,
  protected sessionProvier: SessionClientProvider) { 

  super(screenModeService, deviceService, messageService, sessionProvier);
}
ngOnInit(): void {
  if(!this.item) 
    this.item = { 
      idArticulo: this.idArticulo,
      estadoRegreso: this.funciona,
      /* asignado: this.disponible == true; */
      /* cantidad: this.environment.pzRegister */
    };
    this.initEstados();
}

dialogSize(): any{
  if(this.isMobile()){
    return {width: '90vw', height:'90%'};
  }

  return {width: '60vw', height:'60%'};
}
gridSize(): string{
  if(this.isMobile()){
    return 'height:76vh';
  }

  return 'height:46vh';
}
onHideDialog(event){
  this.onHide.emit();
}

onGuardar(event){
  if( this.item.comentario) {
    this.item.idArticulo = this.idArticulo;
    this.successMessage("Listo", "Guardado");
    this.psEntrada.registrarEntrada(this.item).subscribe(
      (data) => {
        this.onHideEntrada.emit(true);
      },
      (error)=>{
        console.debug("No se asigno la herramienta: ");
        console.debug(error);
      }
    )

  }
  else {
    this.infoMessage("Atencion", "Campos faltantes");
  }
}
onCancelar(event){
  this.display = false;
  /* console.log('Sirve o no boton cancelar') */
}

selectOption(event) {
  this.onChange.emit(this.item.idEstadoArticulo);
}

initEstados() {
  this.clientEstado.find({}).subscribe(
    (data) => {
      this.estados = data;
    },
    (error) => {
      this.errorMessage("Error", "No se pudo cargar el catalogo de estados");
    }
  );
}

esFuncional(event) {

  this.funciona = event.checked;
  
  if (event.checked) {
    this.item.estadoRegreso = true;
  }

  else {
    this.item.estadoRegreso = false;
  }


}

esMantenimiento(event) {

  this.funciona = event.checked;
  
  if (event.checked) {
    this.item.estadoRegreso = false;
  }


}
}