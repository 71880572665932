import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpleadosRestcService extends ARestClient{

  constructor(protected client:HttpClient) {
    super(client);
  }

  getBaseEndpoint(): string {
    let path = environment.client.msEmpleadoPs

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  empleadosActivos(idEmpresa: number): Observable<any[]> {
    let path = this.getBaseEndpoint() + "empleados-contrato-activo/" + idEmpresa;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Headers', '*');

    return this.httpClient.get(path, { headers }) as Observable<any[]>;
  }
}
