import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageCodes, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { TipoArticulo } from 'src/app/api/articulo';
import { TipoArticuloPsService } from 'src/app/procs/tipo-articulo-ps.service';

@Component({
  selector: 'axks-tipo-articulo-cat',
  templateUrl: './tipo-articulo-cat.component.html',
  styleUrls: ['./tipo-articulo-cat.component.scss']
})
export class TipoArticuloCatComponent implements OnInit, OnDestroy, OnChanges {

  catalog: TipoArticulo[];
  virtualScroll: boolean = false;

  showForm: boolean = false;
  newItem: TipoArticulo;

  @Input() esHerramienta: boolean;

  @Input() item?: TipoArticulo;
  /**
   * Habilita la funcionalidad para agregar
   */
  @Input() addNewEnable: boolean = false;

  /** 
   * Indica si hay que tomar en cuenta el modo de operación 
   * de la pantalla.
   */
  @Input() operationMode: OperationMode;

  @Output() onChange = new EventEmitter<number>();


  constructor(private psTipoArticulo: TipoArticuloPsService,
    private messages: MessageService,
    private route: ActivatedRoute) {

  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {} as TipoArticulo;
    }
    if (this.operationMode) {
      this.enableAddNewButton();
    }

    // Temporal mientras se desarrolla
    /* this.route.data.subscribe((data) => {
      this.addNewEnable = data.addNewEnable;
      this.populate();
    }) */

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
    this.enableAddNewButton();
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
        this.addNewEnable = true;
        break;
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {
      categoria: {
        id: this.esHerramienta ? 2 : 1
      }
    } as TipoArticulo;

    this.virtualScroll = false;
    this.psTipoArticulo.find(filter).subscribe(
      {
        next: (data) => {
          this.catalog = data as TipoArticulo[];
          if (this.catalog.length > 10) {
            this.virtualScroll = true;
          }
        },
        error: (error) => {
          try {

            let message = error.error as Message;
            this.processMessage(message);
          } catch (e) {
            console.debug("Error")
            console.debug(error)
          }
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron Tipos de articulo. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo Tipos de artículo.", detail: messText });
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = {} as TipoArticulo;
  }

  onSaveClick(event) {
    this.psTipoArticulo.register(this.newItem).subscribe(
      {
        next: (data) => {
          this.populate();
          this.showForm = false;
          this.newItem = undefined;
        },
        error: (error) => {
          try {
            let message = error.error as Message;
            this.processMessage(message);
          } catch (e) {
            console.debug("Error")
            console.debug(error)
          }
          this.showForm = false;
          this.newItem = undefined;
        }
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = undefined;
  }

  selectOption(event) {
    this.onChange.emit(this.item.id);
  }

}

