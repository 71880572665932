import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoMovimiento } from 'src/app/api/articulo';
import { TipomovimientoPsService } from 'src/app/procs/tipomovimiento-ps.service';
@Component({
  selector: 'axks-tipomovimiento',
  templateUrl: './tipomovimiento.component.html',
  styleUrls: ['./tipomovimiento.component.scss']
})
export class TipomovimientoComponent extends BaseMainComponent<TipomovimientoPsService> implements OnInit{

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    protected tipoMovimientoPs: TipomovimientoPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }
  initEmptyItem(): ComponentItem {
    return {
      item: {} as TipoMovimiento,
      minimumItem: {} as TipoMovimiento,
      registerItem: {} as TipoMovimiento
    } as ComponentItem;
  }
  getService(): TipomovimientoPsService {
    return this.tipoMovimientoPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {field: "id", header: "Clave", inAll:true},
      {field: "tipoMovimiento", header:"Tipo de Movimiento", inAll:true}
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "TipoMovimiento";
  }

  componentsFields() {
    super.componentsFields();
    this.formFields = ["tipoMovimiento", "id",];
    this.formSearch = ["tipoMovimiento", "id",];
  }
}
