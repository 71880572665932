import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { OperationMode } from '@axks/components';
import { EstadoarticuloPsService } from 'src/app/procs/estado-articulo-ps.service';
import { Articulo, EstadoArticulo } from 'src/app/api/articulo';
import { ProyectoPsService } from 'src/app/procs/proyecto-ps.service';
import { ProyectosPsService } from 'src/app/procs/proyectos-ps.service';
@Component({
  selector: 'axks-articulo-form',
  templateUrl: './articulo-form.component.html',
  styleUrls: ['./articulo-form.component.scss']
})
export class ArticuloFormComponent extends BaseView implements OnInit {
  @Input() item: Articulo;
  estados: EstadoArticulo[];
  @Output() onChange = new EventEmitter<number>();
  @Output() onBuscaDisponibles = new EventEmitter<boolean>();
  virtualScroll: boolean = false;
  esBodega: boolean = false;
  esAsignado: boolean = false;
  proyectos: any[];
  @Input() idCategoria: Number=2;
  //const categoria?: Number = 1;
  @Input() addNewEnable: boolean = false;
  showForm: boolean = false;
  OPERATIONMODE = OperationMode;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    private clientEstado: EstadoarticuloPsService,
    private clientProyecto: ProyectosPsService,
    protected sessionProvier: SessionClientProvider) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }


  ngOnInit(): void {
    if (!this.item)
      this.item = {
        //idCategoria: this.idCategoria =2,
      };

    this.initEstados();
    this.initProyectos();
  }


  selCategoria(event) {
    this.item.idCategoria = event;
  }

  selTipoArticulo(event) {
    this.item.idTipoArticulo = event;
  }

  selMarca(event) {
    this.item.idMarca = event;
  }

  selProyecto(event) {
    this.item.idProyecto = event;
  }



  selectOption(event) {
    this.onChange.emit(this.item.idEstadoArticulo);
  }

  initEstados() {
    this.clientEstado.find({}).subscribe(
      (data) => {
        this.estados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo cargar el catalogo de estados");
      }
    );
  }

  initProyectos() {
    this.clientProyecto.find({}).subscribe(
      (data) => {
        this.proyectos = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo cargar el catalogo de proyectos");
      }
    );
  }

/*   initMantenimiento() {
    this.clientArticulo.register(
      (data) => {
        this.proyectos = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo cargar el catalogo de proyectos");
      }
    );
  } */



  esActivo(event) {

    this.esBodega = event.checked;
    
    if (event.checked) {
      this.item.idProyecto = null;
    }

    

    /* console.log(event);
    if (event.checked){
      this.esBodega = true;
    }
    else {
     this.esBodega = false;
    } */
  }

/*   estaAsignado(event) {

    this.esAsignado = event.checked;
    
    if (event.checked) {
      this.item.idProyecto = null;
    }
  } */

  marcaDisponibles(event) {
    this.onBuscaDisponibles.emit(event.checked);
  }
}
