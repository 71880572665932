import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { EstadoarticuloRestcService } from '../client/estado-articulo-restc.service';

@Injectable({
  providedIn: 'root'
})
export class EstadoarticuloPsService extends BaseProcess<EstadoarticuloRestcService> {

  constructor(httpClient: HttpClient, crest: EstadoarticuloRestcService) {
    super(httpClient, crest);
   }
}