import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoArticulo, UnidadMedida } from 'src/app/api/articulo';
import { TipoArticuloPsService } from 'src/app/procs/tipo-articulo-ps.service';
import { UnidadMedidaPsService } from 'src/app/procs/unidad-medida-ps.service';

@Component({
  selector: 'axks-tipo-articulo',
  templateUrl: './tipo-articulo.component.html',
  styleUrls: ['./tipo-articulo.component.scss']
})
export class TipoArticuloComponent extends BaseMainComponent<TipoArticuloPsService> implements OnInit{
  

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    protected tipoArtituloPs: TipoArticuloPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }


  initEmptyItem(): ComponentItem {
    return {
      item: {unidadMedida:{}} as TipoArticulo,
      minimumItem: {unidadMedida:{}} as TipoArticulo,
      registerItem: {} as TipoArticulo
    } as ComponentItem;
  }
  getService(): UnidadMedidaPsService {
    return this.tipoArtituloPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {field: "id", header: "Clave", inAll:true},
      {field: "tipoArticulo", header: "Tipo de artículo", inAll:true},
      {field: "unidadMedida.unidadMedida", header:"Unidad de medida", inAll:true}
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Tipo de artículo";
  }
}
