<!-- <div *ngIf="operationMode==OPERATIONMODE.SEARCH" class="grid frm-part">
   {{item | json}} 
    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-12" *ngIf="allowFormFields('idArticulo')">
        <axks-articulo-cat [value]="item.articulo" [addNewEnable]="true" 
        (onChange)="selectArticulo($event)" [operationMode]="operationMode"></axks-articulo-cat>
    </div>
    
    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-12" *ngIf="allowFormFields('idTipoMovimiento') && filterSelected">
        <axks-tipomovimiento-cat [value]="item.tipoMovimiento" [addNewEnable]="true" 
        (onChange)="selectTipo($event)" [operationMode]="operationMode"></axks-tipomovimiento-cat>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('partesUnidad') && filterSelected">
        <br>
        <span class="p-float-label">
            <input id="fl-partesUnidad" type="text" pInputText [(ngModel)]="item.partesUnidad">
            <label for="fl-partesUnidad">Partes unidad</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('cantidadUnidad') && filterSelected">
        <br>
        <span class="p-float-label">
            <input id="fl-cantidadUnidad" type="text" pInputText [(ngModel)]="item.cantidadUnidad">
            <label for="fl-cantidadUnidad">Cantidad unidad</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('fecMovimiento') && filterSelected">
        <br>

        <span class="p-float-label">
            <p-calendar id="fl-fecMov" [(ngModel)]="item.fecMovimiento" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [yearRange]="yearRange" [monthNavigator]="true" dataType="string"></p-calendar>
            <label for="fl-fecMov">Fecha Movimiento</label>
        </span>

    </div>


    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('nombreEmpleado') && filterSelected">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-idempleado" [(ngModel)]="item.idEmpleado" [options]="empleadosNombre"
                optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false"
                [virtualScroll]="virtualScroll" [virtualScrollItemSize]="10" filter="true" filterBy="nombreCompleto"
                autoWidth="false" (onChange)="selectOption($event)"
                [styleClass]="addNewEnable && !showForm ? 'with-button':''" [showClear]="true"
                [style]="{'width': '100%'}"></p-dropdown>
            <label for="fl-idempleado">Empleado</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-12" *ngIf="allowFormFields('idProyecto') && filterSelected">
        <axks-proyecto-cat [value]="item.proyecto" [addNewEnable]="true" 
        (onChange)="selProyecto($event)" [operationMode]="operationMode"></axks-proyecto-cat>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('comentario') && filterSelected">
        <br>
        <span class="p-float-label">
            <input id="fl-comentario" type="text" pInputText [(ngModel)]="item.comentario">
            <label for="fl-comentario">Comentario</label>
        </span>
    </div>

</div> -->

<!-- Modo registro -->
<div class="grid frm-part">

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-12" *ngIf="allowFormFields('idArticulo')">
        <axks-articulo-cat [value]="item.articulo" [addNewEnable]="true" 
        (onChange)="selectArticulo($event)" [operationMode]="operationMode"></axks-articulo-cat>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-12" *ngIf="allowFormFields('idTipoMovimiento')">
        <axks-tipomovimiento-cat [value]="item.tipoMovimiento" [addNewEnable]="true" 
        (onChange)="selectTipo($event)" [operationMode]="operationMode"></axks-tipomovimiento-cat>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('partesUnidad')">
        <br>
        <span class="p-float-label">
            <input id="fl-partesUnidad" type="text" pInputText [(ngModel)]="item.partesUnidad">
            <label for="fl-partesUnidad">Partes unidad</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('cantidadUnidad')">
        <br>
        <span class="p-float-label">
            <input id="fl-cantidadUnidad" type="text" pInputText [(ngModel)]="item.cantidadUnidad">
            <label for="fl-cantidadUnidad">Cantidad unidad</label>
        </span>
    </div>


    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('fecMovimiento')">
        <br>

        <span class="p-float-label">
            <p-calendar id="fl-fecMov" [(ngModel)]="item.fecMovimiento" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [yearRange]="yearRange" [monthNavigator]="true" dataType="string"></p-calendar>
            <label for="fl-fecMov">Fecha Movimiento</label>
        </span>

    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('nombreEmpleado')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-idempleado" [(ngModel)]="item.idEmpleado" [options]="empleadosNombre"
                optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false"
                [virtualScroll]="virtualScroll" [virtualScrollItemSize]="10" filter="true" filterBy="nombreCompleto"
                autoWidth="false" (onChange)="selectOption($event)"
                [styleClass]="addNewEnable && !showForm ? 'with-button':''" [showClear]="true"
                [style]="{'width': '100%'}"></p-dropdown>
            <label for="fl-idempleado">Empleado</label>
        </span>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-12" *ngIf="allowFormFields('idProyecto')">
        <axks-proyecto-cat [value]="item.proyecto" [addNewEnable]="true" 
        (onChange)="selProyecto($event)" [operationMode]="operationMode"></axks-proyecto-cat>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('comentario')">
        <br>
        <span class="p-float-label">
            <input id="fl-comentario" type="text" pInputText [(ngModel)]="item.comentario">
            <label for="fl-comentario">Comentario</label>
        </span>
    </div>
</div>