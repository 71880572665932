import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProyectosRestcService extends ARestClient{

  constructor(protected client:HttpClient) {
    super(client);
  }

  getBaseEndpoint(): string {
    let path = environment.client.msProyectodev

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }
}
