
<div class="grid frm-part">
    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('tipoArticulo')">
        <br>
        <span class="p-float-label">
            <input id="fl-tipoArticulo" type="text" pInputText [(ngModel)]="item.tipoArticulo">
            <label for="fl-tipoArticulo">Tipo de articulo</label>
        </span>
    </div>
    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('unidadMedida')">
        <axks-unidad-medida-cat [value]="item.unidadMedida" [addNewEnable]="true" (onChange)="changeUnidadMedida($event)"></axks-unidad-medida-cat>
    </div>

    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" *ngIf="allowFormFields('categoria')">
        <axks-categoria-cat [value]="item.categoria" [addNewEnable]="true" (onChange)="changeCategoria($event)"></axks-categoria-cat>
    </div>
</div>
