<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    
    <!-- {{item.registerItem | json}} -->
    <!-- {{item.item | json}} -->
    <axks-operations-bar (addNew)="addNew($event)" [addNewButton]="true" [searchButton]="true"
        [sectionTitle]="getComponentTitle()" (filter)="filterClick($event)" [filterButton]="true"
        [searchButton]="false">
    </axks-operations-bar>

    <p-overlayPanel #searchFilter (onHide)="hideFilter($event)">
        <ng-container *ngIf="showFilter">
            <axks-articulo-form #cmpForm id="cmpForm" [item]="workItem()" [formSearch]="formSearch"
               [formFields]="formFields" [operationMode]="operationMode"></axks-articulo-form>

            <axks-actions-bar (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
                cleanLabel="Limpiar">
            </axks-actions-bar>
        </ng-container>

    </p-overlayPanel>

    <axks-detail-tab *ngIf="showDetail()" [data]="workItem()" [colsDefinition]="colDefinition"
        [detailFields]="detailFields" withLateral="laterlaMode()" [style]="isDesktop()?'--width: 87%':''">
    </axks-detail-tab>

    <axks-articulo-form #cmpForm id="cmpForm" *ngIf="showForm() && showFilter==false" [item]="workItem()"
        [formSearch]="formSearch" [formFields]="formFields" [operationMode]="operationMode"
        [class]="'cmp-form-' + operationMode" [ngStyle]="{float: isSearch()?'left':'unset'}"
        [class]="'form-container ' + (lateralMode() ? 'with-lateral' : '')">
    </axks-articulo-form>

    <axks-actions-bar *ngIf="showMainActionsBar()" (save)="save($event)" (cancel)="cancel($event)"
        (clean)="clean($event)" (find)="find($event)" (print)="print($event)" [printButton]="false"
        (edit)="edit($event)" (delete)="delete($event)" (csvFile)="csvFile($event)" (pdfFile)="pdfFile($event)"
        (txtFile)="txtFile($event)" saveIcon="true" cancelIcon="true" cleanIcon="true" findIcon="true" editIcon="true"
        deleteIcon="true" [extraButtons]="extraButtons" [lateral]="lateralMode()"
        [class]="lateralMode() ? 'lateral-bar' : ''">
    </axks-actions-bar>


    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()"
        [class]="!isExpandedResultList() ? 'with-lateral' : ''">
        <ng-container position="caption"></ng-container>
    </axks-result-list>

    <!--  Asi estaba cuando funcionaba   <p-dialog header="Header" [(visible)]="showDialog" [style]="{width: '100vw'} ">
        <axks-articulo-salida-form *ngIf="esSalida" [idArticulo]="workItem().idArticulo"></axks-articulo-salida-form> 

        <axks-articulo-entrada-form *ngIf="esEntrada" [idArticulo]="workItem().idArticulo"> </axks-articulo-entrada-form>    

    </p-dialog> -->

    <p-dialog header="Salida" [(visible)]="showSalida" [style]="{'width': '80vw', 'left': '10px', 'position':'absolute', 'top':'10vh'} " [modal]="true">
        <axks-articulo-salida-form *ngIf="esSalida" [idArticulo]="workItem().idArticulo"
        (onHideSalida)="hideDialogSalida($event)"></axks-articulo-salida-form>
    </p-dialog>

    <p-dialog header="Entrada" [(visible)]="showEntrada" [style]="{'width': '30vw', 'left': '10px', 'position':'absolute', 'top':'10vh'} " [modal]="true">
        <axks-articulo-entrada-form *ngIf="esEntrada" [idArticulo]="workItem().idArticulo"
        (onHideEntrada)="hideDialogEntrada($event)">
        </axks-articulo-entrada-form>
    </p-dialog>

    <p-dialog header="Movimientos del articulo" [(visible)]="showDialog" [modal]="true"
        [style]="{ width: '50vw', height: '400px', 'top':'5vh' }" [draggable]="false" [resizable]="false"
        [breakpoints]="{ '960px': '60vw', '810px': '75vw', '390px' : '90vw' }" (onHide)="esHistorico = false">
        <axks-historico-form *ngIf="esHistorico" [idArticulo]="workItem().idArticulo"></axks-historico-form>
    </p-dialog>

    <p-dialog header="Mantenimiento del articulo" [(visible)]="showMantenimiento" [modal]="true"
    [style]="{ width: '50vw', height: '400px', 'top':'5vh' }" [draggable]="false" [resizable]="false"
    [breakpoints]="{ '960px': '60vw', '810px': '75vw', '390px' : '90vw' }" (onHide)="esMantenimiento = false">
    <axks-mantenimiento-form *ngIf="esMantenimiento" [idArticulo]="workItem().idArticulo"></axks-mantenimiento-form>
</p-dialog>

</ng-container>

<p-toast></p-toast>