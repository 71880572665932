import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { UnidadMedidaRestcService } from '../client/unidad-medida-restc.service';

@Injectable({
  providedIn: 'root'
})
export class UnidadMedidaPsService extends BaseProcess<UnidadMedidaRestcService> {

  constructor(httpClient: HttpClient, crest: UnidadMedidaRestcService) {
    super(httpClient, crest);
   }
}
